import React from 'react';
import Dialog from '../common/Dialog.component';
import { makeStyles } from '@material-ui/core/styles';
import { Box, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import Text from '../common/text.component';
import Button from '../common/button.component';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({

  dialog: {
    width: '40rem',
    borderRadius: '20px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
      minWidth: '92%',
    },
  },
  dialogContent: {
    padding: '3.75rem 5rem !important',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: '3vh 0.5rem 3vh 0.5rem !important',
    },
  },
  button: {
    width: '14.563rem',
    fontSize: '16px',
    marginTop: '4.75rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      [theme.breakpoints.landscape()]:{
        marginTop: '4.75vh',
      },
    },
  },
  titleContainer: {
    display: 'flex',
    marginBottom:'1rem'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: '#141414',
  },
  buttonSecundary:{
    marginTop: '1rem',
  },
}));

const InfoBudgetDialog = ({
  show, onShow, title, message, showClose,
  isBtnPrimary, isBtnSecondary, lblBtnPrimary,
  lblBtnSecondary, handleOnClickBtnPrimary,
  handleOnClickBtnSecondary, buttonWidth }) => {

  const classes = useStyles();
  // const theme = useTheme();
  // const isMobileVertical = useMediaQuery(`${theme.breakpoints.up('md')} and (orientation: portrait)`);

  //const horizontal = `${theme.breakpoints.up('md')} and (orientation: portrait)`

  const handleClose = (reason) => {
    if(!showClose && reason === 'backdropClick') return;
    onShow(false);
  };

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      fullScreen={false}
      maxWidth="false"
      classes={{
        paper: classes.dialog,
      }}
    >
      <DialogContent classes={{ root: classes.dialogContent }}>
        <Box
          width="auto"
          margin="auto"
          display="flex"
          flexDirection="column"
          alignContent="center"
          alignItems="center"
        >
          <DialogTitle disableTypography className={classes.titleContainer}>
            <Text large bold center>
              {title}
            </Text>
            {showClose && (
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={()=>onShow(false)}
              >
                <CloseIcon />
              </IconButton>
            )}
          </DialogTitle>

          <Text medium center g600>
            {message}
          </Text>
          { isBtnPrimary &&
            <Button
              className={`${classes.button} ${buttonWidth}`}
              onClick={handleOnClickBtnPrimary}
              primary
            >
              {lblBtnPrimary}
            </Button>
          }

          { isBtnSecondary &&

        <Button
          className={`${classes.button} ${classes.buttonSecundary} ${buttonWidth}`}
          onClick={handleOnClickBtnSecondary}
          outline
        >
          {lblBtnSecondary}
        </Button>

          }
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default InfoBudgetDialog;
