import React from 'react';
import PropTypes from 'prop-types';
import { Select as MuiSelect } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core';

const LOADING_TEXT = 'Cargando...';

const useStyles = makeStyles(theme => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  circularProgress: {
    marginRight: '2rem',
  },
  loadingMenuItem: {
    '&.Mui-selected, &:hover': {
      backgroundColor: theme.palette.grayScale.g0,
    }
  },
  select: {
    borderRadius: theme.borderRadius.large,
    '&:focus': {
      borderRadius: theme.borderRadius.large,
      backgroundColor: theme.palette.grayScale.g0,
    },
  },
  paper: {
    marginTop: '0.5rem',
    borderRadius: theme.borderRadius.large,
    maxHeight: 250,
    width: 0,
    overflowY: 'auto',
    overflowX: 'none',
    boxShadow: 'none',
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.grayScale.g200}`,
    backgroundColor: theme.palette.grayScale.g0,
  },
  menuItemText: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: theme.palette.g400,
    fontSize: theme.typography.size.small
  },
  icon: {
    color: theme.palette.primary.main,
  }
}));

const SelectV2 = ({
  disabled = false,
  defaultValue = 'Ninguno',
  onChange = () => {},
  isFromCatalog,
  label,
  id,
  multiple = false,
  multipleValues = [],
  value = null,
  options,
  loading = false,
  ...props }) => {
  const classes = useStyles();

  const menuProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    getContentAnchorEl: null,
    PopoverClasses: { paper: classes.paper },
  };

  const showDefaultItem = !loading && !multiple;

  return(
    <FormControl variant='outlined' fullWidth>
      <InputLabel id={`${id}-label`}
        disabled={disabled}>{label}</InputLabel>
      <MuiSelect
        autoWidth={false}
        {...props}
        classes={{
          root: classes.select
        }}
        labelId={`${id}-label`}
        label={label}
        disabled={disabled}
        id={id}
        value={multiple ? multipleValues : value}
        onChange={onChange}
        multiple={multiple}
        MenuProps={menuProps}
        input={<OutlinedInput
          disabled={disabled}
          name={label}
          id={`outlined--${label}`}
        />}
      >
        {showDefaultItem && !isFromCatalog &&
            <MenuItem disableRipple value='' selected>
              {defaultValue}
            </MenuItem>
        }
        {loading ?
          <MenuItem  disabled disableRipple className={classes.loadingMenuItem} value=''>
            {LOADING_TEXT}
          </MenuItem>
          :
          multiple ?
            options.map((option, index) => (
              <MenuItem key={index} value={option.value} disableRipple>
                <Checkbox checked={multipleValues.indexOf(option.value) > -1} />
                <div className={classes.menuItemText}>
                  {option.label}
                </div>
              </MenuItem>
            ))
            :
            options.map((option) =>{
              return(
                <MenuItem disableRipple={true} key={option.key || option.value} value={option.value}>
                  <div className={classes.menuItemText}>
                    {option.label}
                  </div>
                </MenuItem>);
            })
        }
      </MuiSelect>
    </FormControl>
  );
};


SelectV2.propTypes = {
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  options: PropTypes.array,
  loading: PropTypes.bool,
};

export default SelectV2;
