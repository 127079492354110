import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, IconButton, Box, Grid } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import MaskedTextField from '../common/MaskedTextField.component';
import RadioButtonsGroup from '../common/RadioButtonsGroup.component';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const YEAR_MASK =  new Array(4).fill(/\d/);

const CURRENT_YEAR = (new Date()).getFullYear();
const MAX_YEAR = CURRENT_YEAR + 1;
const MIN_YEAR = 1900;

const INVALID_VALUES_MESSAGE = 'Año desde es mayor a año hasta';
const EMPTY_VALUES_MESSAGE = 'Debe ingresar un valor';
const YEAR_ZERO_MESSAGE = 'El año tiene que ser mayor a cero';

const YEAR_OPTIONS = [
  CURRENT_YEAR,
  CURRENT_YEAR - 1,
  CURRENT_YEAR - 2,
];

const useStyles = makeStyles((theme) => ({
  textInput: {
    height: '1.5625rem',
    fontSize: theme.typography.size.small,
  },
  iconButtonContainer: {
    color: theme.palette.grayScale.g500,
    border: `1px solid ${theme.palette.grayScale.g500}`,
    padding: '0.3rem',
    width: '35px',
    height: '35px',
    '&:hover': {
      borderColor: theme.palette.primary.dark,
      color: theme.palette.primary.dark,
    }
  },
  icon: {
    width: '12px',
    height: '12px',
  },
  clearButton: {
    fontSize: '1rem',
  },
  inputAdornedEnd: {
    paddingRight: '5px',
  },
  boxLabel: {
    '& > span': {
      fontSize: '0.875rem'
    }
  },
  inputContainer: {
    margin: '0 4px',
    '&>div': {
      marginTop: 0
    }
  },
  borderContainer:{
    '& .MuiOutlinedInput-root': {
      '& fieldset':{
        borderColor: '#D1D1D1',
        height: '35px',
      }
    }
  },
  errorContainer: {
    paddingLeft: '1.25rem',
    paddingTop: '0.5rem',
    fontSize: theme.typography.size.small,
    color: '#C53814',
    padding: '5px',
    alignItems: 'flex-start',
  },
  errorContainerDesktop: {
    paddingTop: '0.5rem',
    fontSize: theme.typography.size.small,
    color: '#C53814',
    padding: '5px',
    alignItems: 'flex-start',
  }
}));

const YearRange = ({ onUpdateFilter, minYearRange, maxYearRange }) => {
  const classes = useStyles();

  const [minYear, setMinYear] = React.useState(minYearRange);
  const [maxYear, setMaxYear] = React.useState(maxYearRange);
  const [selectedYear, setSelectedYear] = React.useState(null);
  const [rangeIsInvalid, setRangeIsInvalid] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  useEffect(() => {
    if(maxYearRange != '' && minYearRange != '' && maxYearRange === minYearRange){
      clearFilterRange();
      setSelectedYear(maxYearRange);
    } else {
      setMinYear(minYearRange);
      setMaxYear(maxYearRange);
      clearRadioButtons();
    }
  }, [maxYearRange, minYearRange]);

  const clearRadioButtons = () => setSelectedYear(null);

  const clearFilterRange = () => {
    setMinYear(null);
    setMaxYear(null);
    setRangeIsInvalid(false);
    setErrorMessage('');
  };

  const invalidateInput = (message) => {
    setRangeIsInvalid(true);
    setErrorMessage(message);
    return false;
  };

  const checkValidRanges = (min, max) => {
    const maxLength = min?.toString().length || 0;
    const minLength = max?.toString().length || 0;

    if( maxLength === 0 && minLength === 0 ) {
      return invalidateInput(EMPTY_VALUES_MESSAGE);
    }

    if(min === '0' || max === '0') {
      return invalidateInput(YEAR_ZERO_MESSAGE);
    }

    if(( minLength === 4 && maxLength === 4 &&
       ( min > max || min > MAX_YEAR || min < MIN_YEAR || max > MAX_YEAR || max < MIN_YEAR) )
    || ( minLength > 0 && minLength < 4 )
    || ( maxLength > 0 && maxLength < 4 )
    ) {
      return invalidateInput(INVALID_VALUES_MESSAGE);
    }

    setRangeIsInvalid(false);
    setErrorMessage('');
    return true;
  };

  const updateFilterByRange = () => {
    if (checkValidRanges(minYear, maxYear))
      onUpdateFilter('year', {
        yearMin: minYear,
        yearMax: maxYear,
      });
  };

  const updateFilterByRadio = (value) => {
    onUpdateFilter('year', {
      yearMin: value,
      yearMax: value,
    });
  };

  const handleOnChangeRadio = ({ target: { value: eventValue } }) => {
    clearFilterRange();
    setSelectedYear(parseInt(eventValue));
    updateFilterByRadio(eventValue);
  };

  const handleMaxYear = (event) => {
    clearRadioButtons();
    const value = event.target.value;
    const valueLength = value.toString().length;
    if(valueLength === 4 && (value > MAX_YEAR || value < MIN_YEAR)) setRangeIsInvalid(true);
    else setRangeIsInvalid(false);
    setMaxYear(value);
  };

  const handleMinYear = (event) => {
    clearRadioButtons();
    const value = event.target.value;
    const valueLength = value.toString().length;
    if(valueLength === 4 && (value > MAX_YEAR || value < MIN_YEAR)) setRangeIsInvalid(true);
    else setRangeIsInvalid(false);
    setMinYear(value);
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box display="flex" flexDirection="column" alignItems="left">
      <Box display="flex" flexDirection="row">
        <RadioButtonsGroup
          data-test-id="range-filter"
          onChange={handleOnChangeRadio}
          value={parseInt(selectedYear)}
          options={YEAR_OPTIONS.map(option => ({
            label: option,
            value: option,
          }))}
        />
      </Box>

      <Box display="flex" flexDirection="row" alignItems="center" paddingTop="3px">
        <Box display="flex" flexDirection="row">
          <Grid alignItems="center" className={classes.test}>
            <Grid className={classes.inputContainer}>
              <MaskedTextField
                value={minYear}
                mask={YEAR_MASK}
                onChange={handleMinYear}
                variant="outlined"
                size="small"
                placeholder="Desde"
                data-test-id="yearRangeMinText"
                InputProps={{
                  classes: {
                    root: classes.textInput,
                    adornedEnd: classes.inputAdornedEnd,
                  },
                }}
                error={rangeIsInvalid}
                className={classes.borderContainer}
              />
            </Grid>
          </Grid>
          <Grid alignItems="center">
            <Grid className={classes.inputContainer}>
              <MaskedTextField
                value={maxYear}
                mask={YEAR_MASK}
                onChange={handleMaxYear}
                variant="outlined"
                placeholder="Hasta"
                size="small"
                data-test-id="yearRangeMaxText"
                InputProps={{
                  classes: {
                    root: classes.textInput,
                    adornedEnd: classes.inputAdornedEnd,
                  },
                }}
                error={rangeIsInvalid}
                className={classes.borderContainer}
              />
            </Grid>
          </Grid>
        </Box>
        <Box id="button" marginLeft="0.25rem">
          <IconButton
            variant='contained'
            type='submit'
            color="primary"
            data-test-id="buttonYear"
            onClick={updateFilterByRange}
            className={classes.iconButtonContainer}>
            <ArrowForwardIcon className={classes.icon}/>
          </IconButton>
        </Box>
      </Box>
      {errorMessage &&
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            className={isMobile ? classes.errorContainer : classes.errorContainerDesktop}>
            {errorMessage}
          </Box>
      }
    </Box>
  );
};

YearRange.propTypes = {
  onUpdateFilter: PropTypes.func.isRequired,
  minRange: PropTypes.string.isRequired,
  maxRange: PropTypes.string.isRequired,
  inputMask: PropTypes.array,
  radioOptions: PropTypes.array,
};

export default YearRange;
