import React, { useState, useEffect } from 'react';
import Layout from '../../components/layouts/layout.component';
import ListaCatalogoContainer from './ListaCatalogo.container';
import SearchCatalogContainer from '../catalog-filters/searchCatalog.container';
import CatalogFilters from '../catalog-filters/catalogFilters.container';
import { GET_FILTERED_CARS } from '../../queries';
import { useQuery } from '@apollo/react-hooks';
import { Grid } from '@material-ui/core';
import { navigate } from 'gatsby';
import queryString from 'query-string';
import { makeStyles } from '@material-ui/core/styles';
import CatalogFiltersDialog from '../../components/catalogFiltersDialog/catalogFiltersDialog.component';
import CatalogSortDialog from '../../components/catalogSortDialog/catalogSortDialog.component';
import { useIsLoggedUser } from '../../utils/hooks';
import InfoBudgetDialog from '../../components/budget/InfoBudgetDialog.component';
import Text from '../../components/common/text.component';
import { useAppContext } from '../../context/AppContext';
import { capitalizeEachWord, getFirstWord } from '../../utils/typography.utils';
import { isResellerUser } from '../../api/authorization';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: `0 ${theme.space.horizontal.mobile}`
    },
    [theme.breakpoints.up('md')]: {
      padding: `0 ${theme.space.horizontal.desktop}`
    },
  },
  filters: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));

const PAGE_TITLE = 'Catálogo de Autos - Carmuv';
// eslint-disable-next-line max-len
const META_DESCRIPTION = 'Conocé todos los autos que se comercializan en Argentina. Encontrá en nuestro catálogo fichas tecnicas, fotos e información actualizada.';

const newCarOptions = [
  {
    value: { field: 'price', order:'ASC' },
    label: 'Menor precio'
  },
  {
    value: { field: 'price', order:'DESC' },
    label: 'Mayor precio'
  }
];

const usedCarsOptions = [
  {
    value: { field: 'price', order:'ASC' },
    label: 'Menor precio'
  },
  {
    value: { field: 'price', order:'DESC' },
    label: 'Mayor precio'
  },
  {
    value: {field: 'kilometros', order:'DESC'},
    label: 'Mayor kilometraje'
  },
  {
    value: {field: 'kilometros', order:'ASC'},
    label: 'Menor kilometraje'
  },
  {
    value: {field: 'year', order:'ASC'},
    label: 'Mayor antigüedad'
  },
  {
    value: {field: 'year', order:'DESC'},
    label: 'Menor antigüedad'
  }
];

const MESSAGE_INFO_BUDGET =
  <Text>Esta oferta de auto no esta disponible<br></br> dentro de tus beneficios.<br></br><br></br>
  Te invitamos a ver en nuestro catálogo otro<br></br> auto que te interese</Text>;
const LABEL_BTN_INFO_BUDGET = 'Ver otros autos';


const CatalogPageContainer = ({ location, pageContext }) => {
  const { page, ...search } = queryString.parse(location.search);
  const classes = useStyles();
  const [ filters, setFilters ] = useState({ ...search, condicion: '0' });
  const [ showFilters, setShowFilters ] = useState(false);
  const [ showOrder, setShowOrder ] = useState(false);
  const [ showInfoBudget, setShowInfoBudget ] = useState(false);
  const [orderOptions, setOrderOptions] = useState(newCarOptions);
  const [sort, setSort] = useState('');
  const [labelSort, setLabelSort] = useState('');
  const { isLoggedIn } = useIsLoggedUser();
  const { user: contextLogin, logsInForAReservation } = useAppContext();
  const { profile: { firstname: userName, role_name: roleName } = {} } = contextLogin;
  const isReseller = isLoggedIn && isResellerUser(roleName);
  const {
    precioMin: precioDesde,
    precioMax: precioHasta,
    yearMin: yearDesde,
    yearMax: yearHasta,
    kmMin: kilometersDesde,
    kmMax: kilometersHasta,
    marca: brandId,
    modelo: modelId,
    ...otherFilters
  } = filters;
  const [whereClause, setWhereClause] = useState({
    precio: { desde: parseInt(precioDesde), hasta: parseInt(precioHasta) },
    year: { desde: parseInt(yearDesde), hasta: parseInt(yearHasta) },
    kilometersRange: { desde: parseInt(kilometersDesde), hasta: parseInt(kilometersHasta) },
    ...otherFilters
  });

  const { loading: loadingCatalog, error: errorCatalog, data: dataCatalog } = useQuery(GET_FILTERED_CARS, {
    variables: { filter: whereClause, order: sort.order, field: sort.field, page: parseInt(page) },
    fetchPolicy: 'network-only',
  });
  const maxPriceValue = dataCatalog?.cars?.maxLocalResalePrice || 0;
  if (pageContext.breadcrumb.crumbs.length > 2) {
    pageContext.breadcrumb.crumbs.splice(2);
  }

  const getTitleForResellerDialog = (name) => `Hola, ${name}`;

  useEffect(() => {
    const { page, ...search } = queryString.parse(location.search);
    if (search.condicion) {
      setFilters(search);
    } else {
      setFilters({ ...search, condicion: '0' });
    }
  }, [location.search]);

  useEffect(() => {
    if(filters.condicion === '2'){
      setOrderOptions(usedCarsOptions);
    }
    else {
      setOrderOptions(newCarOptions);
    }
  },[filters.condicion]);

  useEffect(() => {
    if(logsInForAReservation.current === true && contextLogin.isLoggedIn){
      setShowInfoBudget(true);
      logsInForAReservation.current = false;
    }
  },[contextLogin, logsInForAReservation]);

  const onUpdateFilter = (key, value, clearPriceFilter, search) => {
    const newFilters = { ...filters, search };
    const isUpdate = newFilters.hasOwnProperty(key);
    if (key === 'precio' || key === 'year') {
      for (const keyName in value) {
        const keyValue = value[keyName];
        if (keyValue !== '0' && keyValue !== '')
          newFilters[keyName] = keyValue;
        else
          delete newFilters[keyName];
      }
    } else if (key === 'kilometersRange') {
      for (const keyName in value) {
        const keyValue = value[keyName];
        if (keyValue !== '')
          newFilters[keyName] = keyValue;
        else
          delete newFilters[keyName];
      }
    } else {
      newFilters[key] = value;
    }

    if (isUpdate && key === 'marca') {
      delete newFilters['modelo'];
    }

    if (isUpdate && (key === 'marca' || key === 'modelo')) {
      delete newFilters['version'];
      delete newFilters['search'];
    }

    if (key === 'condicion') {
      delete newFilters['modelo'];
      delete newFilters['marca'];
      if (value === '0') delete newFilters[key];
      if (value === '1') {
        delete newFilters['yearMin'];
        delete newFilters['yearMax'];
        delete newFilters['kmMin'];
        delete newFilters['kmMax'];
      }
      if(value === '2' && newFilters.kmMax === '0') {
        delete newFilters['kmMin'];
        delete newFilters['kmMax'];
      }
    }

    if(newFilters['yearMin'] === null) {
      delete newFilters['yearMin']
    }

    if(newFilters['yearMax'] === null) {
      delete newFilters['yearMax']
    }

    if(clearPriceFilter) {
      delete newFilters['precioMin'];
      delete newFilters['precioMax'];
    }

    if(newFilters['precioMin'] === null) {
      delete newFilters['precioMin']
    }

    if(newFilters['precioMax'] === null) {
      delete newFilters['precioMax']
    }

    if(newFilters['search'] === null || newFilters['search'] === '') {
      delete newFilters['search']
    }

    navigateWithNewFilters(newFilters);
  };

  const onDeleteFilter = (key) => {
    const newFilters = { ...filters };
    delete newFilters[key];
    if (key === 'search') {
      delete newFilters['search'];
    }
    if (key === 'marca') {
      delete newFilters['modelo'];
    }
    if (key === 'marca' || key === 'modelo') {
      delete newFilters['version'];
    }
    if (key === 'marca' || key === 'modelo' || key === 'version') {
      delete newFilters['search'];
    }
    if (key === 'precio') {
      delete newFilters['precioMin'];
      delete newFilters['precioMax'];
    }
    if (key === 'kilometersRange') {
      delete newFilters['kmMin'];
      delete newFilters['kmMax'];
    }
    if (key === 'year') {
      delete newFilters['yearMin'];
      delete newFilters['yearMax'];
    }
    navigateWithNewFilters(newFilters);
  };

  const onClearFilters = () => {
    navigate('/catalogo/');
  };

  const onSearchCatalog = (searchValue) => {
    const newFilters = { ...filters };
    delete newFilters['marca'];
    delete newFilters['modelo'];
    delete newFilters['version'];
    if (newFilters.condicion && newFilters.condicion === '0') delete newFilters['condicion'];
    newFilters.search = searchValue;
    navigateWithNewFilters(newFilters);
  };

  const navigateWithNewFilters = (newFilters) => {
    setFilters(newFilters);
    navigate(`/catalogo/?${queryString.stringify(newFilters)}`, { replace: true });
  };

  const onApplyFilters = (newFilters) => {
    setFilters(newFilters);
    setShowFilters(false);
    navigate(`/catalogo/?${queryString.stringify(newFilters)}`);
  };

  const handleChangeSort = (value) => {
    setSort(value)
  }

  const handleChangeSortMobile = (value) => {
    setSort(JSON.parse(value))
  }

  const changeSortLabel=(value)=>{
    setLabelSort(value)
  }

  return (
    <Layout
      title={PAGE_TITLE}
      location={location}
      pageContext={pageContext}
      subHeaderChildren={<SearchCatalogContainer onSearchCatalog={onSearchCatalog} isLoggedIn={isReseller} />}
      showSubHeader
      description={META_DESCRIPTION}
    >
      <Grid className={classes.container} container wrap="nowrap" spacing={2}>
        <Grid className={classes.filters} item xs={3}>
          <CatalogFilters
            filters={filters}
            onUpdateFilter={onUpdateFilter}
            onDeleteFilter={onDeleteFilter}
            onClearFilters={onClearFilters}
            isLoggedIn={isReseller}
            maxPriceValue={maxPriceValue}
            loadingCatalog={loadingCatalog}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <ListaCatalogoContainer
            filters={filters}
            defaultPage={page}
            onShowFilters={() => setShowFilters(true)}
            onShowOrder={() => setShowOrder(true)}
            onDeleteFilter={onDeleteFilter}
            onUpdateFilter={onUpdateFilter}
            orderOptions={orderOptions}
            handleChangeSort={handleChangeSort}
            setWhereClause={setWhereClause}
            loading={loadingCatalog}
            data={dataCatalog}
            error={errorCatalog}
            sort={sort}
            labelSort={labelSort}
          />
        </Grid>
      </Grid>
      <CatalogFiltersDialog
        filters={filters}
        open={showFilters}
        onClose={() => setShowFilters(false)}
        onApplyFilters={onApplyFilters}
        isLoggedIn={isReseller}
        maxPriceValue={maxPriceValue}
        onClearFilters={onClearFilters}
      />
      <CatalogSortDialog
        open={showOrder}
        onClose={() => setShowOrder(false)}
        handleChangeSortMobile={handleChangeSortMobile}
        sort={sort}
        orderOptions={orderOptions}
        changeSortLabel={changeSortLabel}
      />
      <InfoBudgetDialog
        show={showInfoBudget}
        onShow={setShowInfoBudget}
        title={getTitleForResellerDialog(capitalizeEachWord(getFirstWord(userName || '')))}
        message={MESSAGE_INFO_BUDGET}
        isBtnPrimary
        lblBtnPrimary={LABEL_BTN_INFO_BUDGET}
        handleOnClickBtnPrimary={()=>setShowInfoBudget(false)}
        showClose={false}
      />
    </Layout>
  );
};

export default CatalogPageContainer;
