import React from 'react';
import ExpandablePanel from '../common/expandablePanel.component';
import RadioButtonsGroup from '../common/RadioButtonsGroup.component';
import PriceRange from './PriceRange.component';
import KilometersRange from './KilometersRange.component';
import YearRange from './YearRange.component';
import { makeStyles } from '@material-ui/styles';
import { useTheme, useMediaQuery } from '@material-ui/core';

const MAX_NUMBER_OF_OPTIONS = 10;
const BRANDS_TO_SHOW = 6;

const conditionOptions = [
  {
    value: '0',
    label: 'Todos'
  },
  {
    value: '1',
    label: '0 Km'
  },
  {
    value: '2',
    label: 'Usados'
  }
];

const combustibleOptions = [
  {
    value: 'NAFTA',
    label: 'Nafta'
  },
  {
    value: 'DIESEL',
    label: 'Diesel'
  }
];

const transmisionOptions = [
  {
    value: 'AUTOMATICO',
    label: 'Automático'
  },
  {
    value: 'MANUAL',
    label: 'Manual'
  }
];

const useStyles = makeStyles(() => ({
  containerPanel: {
    padding: '1rem 0',
    paddingTop: '0.1875rem',

    '& > .MuiBox-root > .MuiBox-root > fieldset': {
      paddingLeft: '1rem',
      paddingBottom: '0.625rem'
    }
  }
}));

const CatalogFilters = ({
  filters = {},
  brands,
  modelos,
  segmentos,
  onUpdateFilter,
  onDeleteFilter,
  isLoggedIn = false,
  priceOptionValues = [],
  selectedRange = 0,
  setSelectedRange = () => {}
}) => {
  const {
    condicion = '0',
    marca = null,
    modelo = null,
    segmento = null,
    combustible = null,
    transmision = null,
    precioMin = '',
    precioMax = '',
    kmMin = '',
    kmMax = '',
    yearMin = '',
    yearMax = '',
  } = filters;
  const updateFilter = (key) => ({ target: { value: eventValue } }, value) => {
    onUpdateFilter(key, value || eventValue);
  };

  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  return (<>
    <ExpandablePanel borderOnTop={false} heading='Condición' isCatalogSection defaultExpanded={!isMobile}>
      <RadioButtonsGroup
        data-test-id="condicion"
        name="condicion"
        value={condicion}
        onChange={updateFilter('condicion')}
        options={conditionOptions}
      />
    </ExpandablePanel>
    <ExpandablePanel borderOnTop={false} heading='Marca' isCatalogSection defaultExpanded={!isMobile}>
      <RadioButtonsGroup
        data-test-id="marca"
        name="marca"
        value={marca}
        onChange={updateFilter('marca')}
        itemsToShow={BRANDS_TO_SHOW}
        options={brands.map(brand => ({
          label: brand.name,
          value: brand.id.toString()
        }))}
      />
    </ExpandablePanel>
    <ExpandablePanel borderOnTop={false} heading='Modelo' isCatalogSection defaultExpanded={!isMobile}>
      <RadioButtonsGroup
        data-test-id="modelo"
        name="modelo"
        value={modelo}
        noOptionsMessage="Seleccioná una marca"
        itemsToShow={MAX_NUMBER_OF_OPTIONS}
        onChange={updateFilter('modelo')}
        options={modelos.map(model => ({
          label: model.name,
          value: model.id.toString()
        }))}
      />
    </ExpandablePanel>
    {condicion !== '1' &&
      <ExpandablePanel
        borderOnTop={false}
        heading='Año'
        isCatalogSection
        defaultExpanded={!isMobile}
        expansionPanelDetailsClass={classes.containerPanel}>
        <YearRange
          minYearRange={yearMin}
          maxYearRange={yearMax}
          onUpdateFilter={onUpdateFilter}
          onDeleteFilter={onDeleteFilter}
        />
      </ExpandablePanel>
    }
    <ExpandablePanel
      borderOnTop={false}
      heading='Rango de precios'
      isCatalogSection
      defaultExpanded={!isMobile}
      expansionPanelDetailsClass={classes.containerPanel}>
      <PriceRange
        precioMin={precioMin}
        precioMax={precioMax}
        onUpdateFilter={onUpdateFilter}
        onDeleteFilter={onDeleteFilter}
        priceOptionValues={priceOptionValues}
        selectedRange={selectedRange}
        setSelectedRange={setSelectedRange}
        isLoggedIn={isLoggedIn}
      />
    </ExpandablePanel>
    {condicion !== '1' &&
      <ExpandablePanel
        borderOnTop={false}
        heading='Kilómetros'
        isCatalogSection
        defaultExpanded={!isMobile}
        expansionPanelDetailsClass={classes.containerPanel} >
        <KilometersRange
          min={kmMin}
          max={kmMax}
          onUpdateFilter={onUpdateFilter}
          onDeleteFilter={onDeleteFilter}
          condition={condicion}
        />
      </ExpandablePanel>
    }
    <ExpandablePanel borderOnTop={false} heading='Tipo de vehículo' isCatalogSection>
      <RadioButtonsGroup
        data-test-id="segmento"
        name="segmento"
        value={segmento}
        itemsToShow={MAX_NUMBER_OF_OPTIONS}
        onChange={updateFilter('segmento')}
        options={segmentos.map(segment => ({
          label: segment.name,
          value: segment.name
        }))}
      />
    </ExpandablePanel>

    <ExpandablePanel borderOnTop={false} heading='Combustible' isCatalogSection>
      <RadioButtonsGroup
        data-test-id="combustible"
        name="combustible"
        value={combustible}
        onChange={updateFilter('combustible')}
        options={combustibleOptions}
      />
    </ExpandablePanel>
    <ExpandablePanel borderOnTop={false} heading='Transmisión' isCatalogSection>
      <RadioButtonsGroup
        data-test-id="transmision"
        name="transmision"
        value={transmision}
        onChange={updateFilter('transmision')}
        options={transmisionOptions}
      />
    </ExpandablePanel>
  </>);
};

export default CatalogFilters;
