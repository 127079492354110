import React from 'react';
import { goToCarDetail } from '../../../api/navigation';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import CarCard from '../../car-card/CarCard.component.jsx';
import CarCardListItem from '../../car-card/CarCardListItem.component.jsx';
import Loading from '../../common/Loading.component';
import Text from '../../common/text.component';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const LOADING_MESSAGE = 'Cargando...';
const NO_RESULT_FOUND_MESSAGE = 'No se encontró ningún resultado.';
const useStyles = makeStyles(theme => ({
  icon: {
    width: '1.875rem',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
    '&.active': {
      color: theme.palette.primary.main,
    },
  },
  gridList: {
    flexWrap: 'wrap',

  },
  gridItem: {
    maxWidth: '33.33333%',
    padding: '8px 10px',
  },
  loading: {
    marginBottom: '10rem',
    marginTop: '10rem',
  },
  cardListItem: {
    width: '100%',
    marginBottom: theme.spacing(2)
  }
}));

const ListView = ({ data, classes, isMobile, isLoggedIn }) => (
  <Grid
    container
    direction="column"
    alignItems="stretch">
    {data.map((car, index) => (
      <Grid
        key={`${car.brand.id}-${car.model.id}-${index}`}
        item xs={12}>
        {
          isMobile ?
            <CarCard
              car={car}
              onCardClick={() => goToCarDetail(car)}
              compact
              className={classes.cardListItem}
              isLoggedIn={isLoggedIn}
              isMobile={isMobile}
            />
            :
            <CarCardListItem
              className={classes.cardListItem}
              car={car}
              onCardClick={() => goToCarDetail(car)}
              isLoggedIn={isLoggedIn} />
        }
      </Grid>
    ))}
  </Grid>
);

const ModuleView = ({ data, classes, total, isLoggedIn }) => (
  <Grid className={classes.gridList} container justify="flex-start">
    {data.map((car, index) => (
      <Grid
        key={`${car.brand.id}-${car.model.id}-${index}`}
        item xs={4}
        className={classes.gridItem}>
        <CarCard
          car={car}
          onCardClick={() => goToCarDetail(car)}
          isLoggedIn={isLoggedIn}
        />
      </Grid>
    ))}
  </Grid>
);

export const ListaCatalogoComponent = ({ data, total, display = 'grid', isLoggedIn = false }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (data.length === 0) {
    return (
      <Box display="flex" justifyContent="center" p={10}>
        <Text bold>{NO_RESULT_FOUND_MESSAGE}</Text>
      </Box>
    );
  }

  if (display === 'grid' && !isMobile) {
    return (
      <ModuleView data={data} classes={classes} total={total} isLoggedIn={isLoggedIn} />
    );
  }
  else {
    return (
      <ListView data={data} classes={classes} isMobile={isMobile} isLoggedIn={isLoggedIn} />
    );
  }
};

export const ListaCatalogoLoading = () => {
  const classes = useStyles();

  return (
    <Grid item className={classes.loading}>
      <Loading />
      <Box display="flex" justifyContent="center">{LOADING_MESSAGE}</Box>
    </Grid>
  );
};
