import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, IconButton, Box, Grid } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import MaskedTextField from '../common/MaskedTextField.component';
import RadioButtonsGroup from '../common/RadioButtonsGroup.component';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const MSG_INVALED_VALUES = 'Precio desde es mayor a precio hasta';
const MSG_EMPTY_VALUES = 'Debe ingresar un valor';

const useStyles = makeStyles((theme) => ({
  textInput: {
    height: '1.5625rem',
    fontSize: theme.typography.size.small,
  },
  iconButtonContainer: {
    color: theme.palette.grayScale.g500,
    border: `1px solid ${theme.palette.grayScale.g500}`,
    padding: '0.3rem',
    width: '35px',
    height: '35px',
    '&:hover': {
      borderColor: theme.palette.primary.dark,
      color: theme.palette.primary.dark,
    }
  },
  icon: {
    width: '12px',
    height: '12px',
  },
  clearButton: {
    fontSize: '1rem',
  },
  inputAdornedEnd: {
    paddingRight: '5px',
  },
  boxLabel: {
    '& > span': {
      fontSize: '0.875rem'
    }
  },
  inputContainer: {
    margin: '0 4px',
    '&>div': {
      marginTop: 0
    }
  },
  borderContainer:{
    '& .MuiOutlinedInput-root': {
      '& fieldset':{
        borderColor: '#D1D1D1',
        height: '35px',
      }
    }
  },
  errorContainer: {
    height: '2rem',
    fontSize: theme.typography.size.small,
    color: '#C53814',
    padding: '5px 5px 5px 1.25rem',
    alignItems: 'flex-start',
  },
  errorContainerDesktop: {
    paddingTop: '0.5rem',
    fontSize: theme.typography.size.small,
    color: '#C53814',
    padding: '5px',
    alignItems: 'flex-start',
  }
}));

const PRICE_MASK =  new Array(9).fill(/\d/);

const PriceRange = ({
  onUpdateFilter,
  precioMin,
  precioMax,
  priceOptionValues = [],
  selectedRange = 0,
  setSelectedRange = () => {},
  isLoggedIn = false
}) => {
  const classes = useStyles();
  const [maxPrice, setMaxPrice] = useState(null);
  const [minPrice, setMinPrice] = useState(null);
  const [isInvalidadInput, setIsInvalidInput] = useState(false);
  const [msgError, setMsgError] = useState();

  useEffect(() => {
    setMaxPrice(null);
    setMinPrice(null);
  }, []);

  const updatePrice = (evt) => {
    evt.preventDefault();
    setSelectedRange(null);
    const maxValueNumber = maxPrice ? parseInt(maxPrice) : null;
    const minValueNumber = (minPrice !== null && minPrice !== undefined) ? parseInt(minPrice) : null;
    if(minValueNumber > maxValueNumber)  {
      if(maxValueNumber === null){
        onUpdateFilter('precio', { precioMin: minPrice, precioMax: maxPrice });
        setIsInvalidInput(false);
        setMsgError();
        return false;
      }
      setIsInvalidInput(true);
      setMsgError(MSG_INVALED_VALUES);
    }else if((maxValueNumber === null && minValueNumber === null) || maxValueNumber === 0){
      setIsInvalidInput(true);
      setMsgError(MSG_EMPTY_VALUES);
    }else{
      onUpdateFilter('precio', { precioMin: minValueNumber, precioMax: maxPrice });
      setIsInvalidInput(false);
      setMsgError();
    }
  };

  const handleChangePriceRangeOption = (event) => {
    const optionSelected = parseInt(event.target.value);
    setMaxPrice(null);
    setMinPrice(null);
    setIsInvalidInput(false);
    setSelectedRange(optionSelected);
    const { minPrice: minPriceOptionValue, maxPrice: maxPriceOptionValue } = priceOptionValues[optionSelected - 1].value
    switch(optionSelected) {
      case 1:
        onUpdateFilter('precio', { precioMin: null, precioMax: maxPriceOptionValue });
        break;
      case 2:
        onUpdateFilter('precio', { precioMin: minPriceOptionValue, precioMax: maxPriceOptionValue });
        break;
      case 3:
        onUpdateFilter('precio', { precioMin: minPriceOptionValue, precioMax: null });
        break;
      default:
        return null;
    }
  }
  function handleMaxPrice (evt) {
    let max = evt.target.value;
    setMaxPrice(max);
    if(isInvalidadInput) setIsInvalidInput(false);
    if(selectedRange){
      setSelectedRange(0);
      setMinPrice(null);
    }
  }

  function handleMinPrice(evt) {
    let min = evt.target.value;
    if(min === ''){
      setMinPrice(null);
    }else{
      setMinPrice(min);
    }
    if(isInvalidadInput) setIsInvalidInput(false);
    if(selectedRange){
      setSelectedRange(0);
      setMaxPrice(null);
    }
  }
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box display="flex" flexDirection="column" alignItems="left">
      <Box display="flex" flexDirection="row">
        <RadioButtonsGroup
          data-test-id="price-range-filter"
          onChange={handleChangePriceRangeOption}
          value={parseInt(selectedRange)}
          options={priceOptionValues.map(priceRangeoption => ({
            label: priceRangeoption.label,
            value: priceRangeoption.value.option,
          }))}
        />
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box display="flex" flexDirection="row">
          <Grid alignItems="center" className={classes.test}>
            <Grid className={classes.inputContainer}>
              <MaskedTextField
                value={minPrice}
                mask={PRICE_MASK}
                onChange={handleMinPrice}
                variant="outlined"
                size="small"
                placeholder="Desde"
                data-test-id="precioMinText"
                InputProps={{
                  classes: {
                    root: classes.textInput,
                    adornedEnd: classes.inputAdornedEnd,
                  },
                }}
                className={classes.borderContainer}
                error={isInvalidadInput}
              />
            </Grid>
          </Grid>
          <Grid alignItems="center">
            <Grid className={classes.inputContainer}>
              <MaskedTextField
                value={maxPrice}
                mask={PRICE_MASK}
                onChange={handleMaxPrice}
                variant="outlined"
                placeholder="Hasta"
                size="small"
                data-test-id="precioMaxText"
                InputProps={{
                  classes: {
                    root: classes.textInput,
                    adornedEnd: classes.inputAdornedEnd,
                  },
                }}
                className={classes.borderContainer}
                error={isInvalidadInput}
              />
            </Grid>
          </Grid>
        </Box>

        <Box id="button" marginLeft="0.25rem">
          <IconButton
            variant='contained'
            type='submit'
            color="primary"
            data-test-id="buttonPrice"
            onClick={(event) => updatePrice(event)}
            className={classes.iconButtonContainer}>
            <ArrowForwardIcon className={classes.icon}/>
          </IconButton>
        </Box>
      </Box>
      {isInvalidadInput &&
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            className={isMobile ? classes.errorContainer : classes.errorContainerDesktop}>
            {msgError}
          </Box>
      }
    </Box>
  );
};

PriceRange.propTypes = {
  precioMin: PropTypes.string.isRequired,
  precioMax: PropTypes.string.isRequired,
  onUpdateFilter: PropTypes.func.isRequired,
};

export default PriceRange;
