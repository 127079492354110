import React from 'react';
import { Grid } from '@material-ui/core';
import CircularProgress from './CircularProgress.component';

const Loading = ({ size, ...props }) => {
  return  (
    <Grid container justify='center' alignItems='center' {...props}>
      <CircularProgress color='primary' size={size}></CircularProgress>
    </Grid>
  );
};

export default Loading;
