import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Slide from '@material-ui/core/Slide';
import Link from '../common/Link.component';
import Logo from '../common/logo.component';
import { DialogContent } from '@material-ui/core';
import CatalogSort from '../../components/catalogSort/catalogSort.component';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    background: theme.palette.grayScale.g0,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  grow: {
    margin: 'auto',
  },
  logo: {
    height: '2.5rem',
    width: '7rem',
    display: 'flex',
  },
  close: {
    color: '#7D10FF'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CatalogSortDialog({ 
  open, 
  onClose, 
  handleChangeSortMobile ,
  sort,
  orderOptions,
  changeSortLabel
  }) {
  const classes = useStyles();

  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition} scroll="paper">
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            className={classes.close}
            onClick={onClose}
            aria-label="close"
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Grid className={classes.grow}>
            <Link to='/' className={classes.logo}>
              <Logo className={classes.logo}/>
            </Link>
          </Grid>
        </Toolbar>
      </AppBar>
      <DialogContent dividers>
        <CatalogSort
          data-test-id="catalog-sort"
          orderOptions={orderOptions}
          handleChangeSortMobile={handleChangeSortMobile}
          sort={sort}
          closeDialog={onClose}
          changeSortLabel={changeSortLabel}
        />
      </DialogContent>
    </Dialog>
  );
}
