import React, { useEffect, useState } from 'react';
import CatalogFilters from '../../components/catalogFilters/catalogFilters.component';
import { useQuery } from '@apollo/react-hooks';
import { GET_SEGMENTOS, GET_CATALOG_MODELS, GET_CATALOG_BRANDS } from '../../queries';
import FilterTags from '../../components/filterTags/filterTags.component';
import FilterTagsTitle from '../../components/filterTags/filterTagsTitle.component';
import { roundOut } from '../../utils/roundOut';
import { formatPrice } from '../../utils/currency.utils';
import _ from 'lodash';

const fuelOptions = ['NAFTA','DIESEL'];
const CURRENT_YEAR = (new Date()).getFullYear();
const MAX_YEAR = CURRENT_YEAR + 1;
const MIN_YEAR = 1900;
const match4digits = /(\d{4})/gm

export default function CatalogFiltersContainer({
  filters,
  onUpdateFilter,
  onDeleteFilter,
  onClearFilters,
  isLoggedIn = false,
  maxPriceValue,
  loadingCatalog = false
}) {
  const [selectedFilterCounter, setSelectedFilterCounter] = useState(0)
  const [priceOptionValues, setPriceOptionValues] = useState([])
  const [selectedRange, setSelectedRange] = useState(0)
  const [isChangingFilters, setIsChangingFilters] = useState(true)
  const [filterToCompare, setFilterToCompare] = useState({...filters})
  const { data: segmentData } = useQuery(GET_SEGMENTOS);
  const { data: brandData } = useQuery(GET_CATALOG_BRANDS, {
    variables: {
      published: true,
      condition: Number(filters.condicion)
    }
  });
  const { data: modelData } = useQuery(GET_CATALOG_MODELS, {
    variables: { brandId: filters.marca, published: true, condition: Number(filters.condicion) }
  });

  const search = filters?.search?.toUpperCase()

  useEffect(() => {
    const filterWithoutPrice = {...filters}
    delete filterWithoutPrice['precioMin'];
    delete filterWithoutPrice['precioMax'];
    delete filterToCompare['precioMax'];
    delete filterToCompare['precioMin'];
    const filterHasChanges = !_.isEqual(filterWithoutPrice, filterToCompare)
    if(filterHasChanges) {
      setIsChangingFilters(true)
    }
  }, [filters])
  
  useEffect(() => {
    if (search) {
      const fuelSearched = fuelOptions.find(fuel => search.toUpperCase().includes(fuel))
      if (fuelSearched) {
        const searchFuelCInsensitive = new RegExp(fuelSearched, 'ig')
        const [matchedFuel] = search.match(searchFuelCInsensitive, '')
        const cleanedSearch = search.replace(matchedFuel, '')
        onUpdateFilter('combustible', fuelSearched, false, cleanedSearch)
      }
    }
  }, [filters.combustible, search, onUpdateFilter]);

  useEffect(() => {
    if (search) {
      const [foundYear] = search.match(match4digits) || [];
      if (foundYear && (foundYear < MAX_YEAR || foundYear > MIN_YEAR)) {
        const cleanedSearch = search.replace(foundYear, '')
        onUpdateFilter('year', {
          yearMin: foundYear,
          yearMax: foundYear,
        },
        false,
        cleanedSearch);
      }
    }
  }, [filters.yearMin, filters.yearMax, search, onUpdateFilter]);

  useEffect(() => {
    if (brandData && !filters.marca && search) {
      for(let i = 0; i < brandData.catalogBrands.length; i++) {
        const brand = brandData.catalogBrands[i];
        if(search.indexOf(brand.name) > -1) {
          onUpdateFilter('marca', brand.id.toString(), false, search);
        }
      }
    }
  }, [brandData, filters.marca, search, onUpdateFilter]);
  
  useEffect(() => {
    if (modelData && !filters.modelo && search) {
      for(let i = 0; i < modelData.catalogModels.length; i++) {
        const modelo = modelData.catalogModels[i];
        if(search.indexOf(modelo.name) > -1) {
          onUpdateFilter('modelo', modelo.id.toString(), false, search);
          break;
        }
      }
    }
  }, [filters.modelo, search, modelData, onUpdateFilter]);

  useEffect(() => {
    if(modelData && brandData && search && filters.marca && filters.modelo) {
      const selectedModelId = filters.modelo.split(',');
      const model = modelData.catalogModels.find(m =>
        selectedModelId.every(id =>
          m.id.includes(id)
        )
      );
      const selectedBrandId = filters.marca.split(',');
      const brand = brandData.catalogBrands.find(b =>
        selectedBrandId.every(id =>
          b.id.includes(id)
        )
      );
      if(!model || !brand) return
      const version = search
        ?.replace(brand.name, '')
        ?.replace(model.name, '')
        .trim();
      if(filters.version !== version) {
        onUpdateFilter('version', version)
      }
    }
  }, [modelData, brandData, filters.search, filters.marca, filters.modelo, onUpdateFilter, filters.version]);
  
  useEffect(() => {
    let counter = 0;
    if(isLoggedIn) {
      counter = Object.keys(filters).filter((key) => key !== 'search').length;
      if(filters.condicion === '0') counter--
    } else {
      counter = Object.keys(filters).filter((key) => key !== 'search' && key !== 'condicion').length;
    }
    setSelectedFilterCounter(counter)
  }, [filters, isLoggedIn])

  useEffect(() => {
    if(maxPriceValue && isChangingFilters && !loadingCatalog) {
      let valueArray = []
      const thirtyThreePercentOfMaximium = (maxPriceValue * 33) / 100
      const sixtySixPercentOfMaximium = (maxPriceValue * 66) / 100
      const firstOptionValue = roundOut(thirtyThreePercentOfMaximium, 10000)
      valueArray.push({label: `Hasta ${formatPrice(firstOptionValue)}`, value: {option: 1, maxPrice: firstOptionValue}})
      const secondOptionValue = roundOut(sixtySixPercentOfMaximium, 10000)
      valueArray.push({label: `${formatPrice(firstOptionValue)} a ${formatPrice(secondOptionValue)}`, value: {option: 2, minPrice: firstOptionValue, maxPrice: secondOptionValue}})
      valueArray.push({label: `Más de ${formatPrice(secondOptionValue)}`, value: {option: 3, minPrice: secondOptionValue}})
      setPriceOptionValues(valueArray)
      setFilterToCompare(filters)
      setIsChangingFilters(false)
    }
  }, [maxPriceValue, isChangingFilters, loadingCatalog])

  const segmentos = segmentData?.segments || [];
  const brands = brandData?.catalogBrands || [];
  const modelos = modelData?.catalogModels || [];

  const handleUpdateFilter = (key, value) => {
    const clearPriceFilter = key !== 'precio' && selectedRange !== 0
    if(clearPriceFilter) setSelectedRange(0);
    onUpdateFilter(key, value, clearPriceFilter);
  };

  const handleDeleteFilter = (key) => {
    setSelectedRange(0)
    onDeleteFilter(key);
  };

  const handleClearFilters = () => {
    onClearFilters();
    setSelectedRange(0)
  };

  return (<>
    <FilterTagsTitle onDeleteAll={handleClearFilters} count={selectedFilterCounter} />
    <FilterTags
      data-test-id="catalog-filter-tags"
      filters={filters}
      brands={brands}
      modelos={modelos}
      onDelete={handleDeleteFilter}
      isLoggedIn={isLoggedIn}
    />
    <CatalogFilters
      data-test-id="catalog-filters"
      filters={filters}
      brands={brands}
      modelos={modelos}
      segmentos={segmentos}
      onUpdateFilter={handleUpdateFilter}
      onDeleteFilter={handleDeleteFilter}
      isLoggedIn={isLoggedIn}
      priceOptionValues={priceOptionValues}
      selectedRange={selectedRange}
      setSelectedRange={setSelectedRange}
    />
  </>);
}
