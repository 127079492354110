import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Slide from '@material-ui/core/Slide';
import Link from '../common/Link.component';
import Logo from '../common/logo.component';
import { DialogActions, DialogContent, Box } from '@material-ui/core';
import Button from '../common/button.component';
import { useState } from 'react';
import { useEffect } from 'react';
import CatalogFiltersContainer from '../../containers/catalog-filters/catalogFilters.container';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    background: theme.palette.grayScale.g0,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  grow: {
    margin: 'auto',
  },
  logo: {
    height: '2.5rem',
    width: '7rem',
    display: 'flex',
  },
  close: {
    color: theme.palette.secondary.main
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CatalogFiltersDialog({
  open,
  onClose,
  onApplyFilters,
  filters: filtersProp,
  isLoggedIn,
  maxPriceValue,
  onClearFilters = () => {}
  }) {
  const [filters, setFilters] = useState(filtersProp);
  const classes = useStyles();

  useEffect(() => {
    setFilters(filtersProp);
  }, [filtersProp]);

  const onUpdateFilter = (key, value, clearPriceFilter, search) => {
    const newFilters = { ...filters, search };
    const isUpdate = newFilters.hasOwnProperty(key);
    if (key === 'precio' || key === 'year') {
      for (const keyName in value) {
        const keyValue = value[keyName];
        if (keyValue !== '0' && keyValue !== '')
          newFilters[keyName] = keyValue;
        else
          delete newFilters[keyName];
      }
    } else if (key === 'kilometersRange') {
      for (const keyName in value) {
        const keyValue = value[keyName];
        if (keyValue !== '')
          newFilters[keyName] = keyValue;
        else
          delete newFilters[keyName];
      }
    }
    else {
      newFilters[key] = value;
    }

    if (isUpdate && key === 'marca') {
      delete newFilters['modelo'];
    }

    if (isUpdate && (key === 'marca' || key === 'modelo')) {
      delete newFilters['version'];
      delete newFilters['search'];
    }

    if (key === 'condicion') {
      delete newFilters['modelo'];
      delete newFilters['marca'];
      if (value === '0') delete newFilters[key];
      if (value === '1') {
        delete newFilters['yearMin'];
        delete newFilters['yearMax'];
        delete newFilters['year'];
        delete newFilters['kmMin'];
        delete newFilters['kmMax'];
      }
      if(value === '2' && newFilters.kmMax === '0') {
        delete newFilters['kmMin'];
        delete newFilters['kmMax'];
      }
    }

    if(newFilters['yearMin'] === null) {
      delete newFilters['yearMin'];
    }

    if(newFilters['yearMax'] === null) {
      delete newFilters['yearMax'];
    }

    if(clearPriceFilter) {
      delete newFilters['precioMin'];
      delete newFilters['precioMax'];
    }

    if(newFilters['precioMin'] === null) {
      delete newFilters['precioMin'];
    }

    if(newFilters['precioMax'] === null) {
      delete newFilters['precioMax'];
    }

    if(newFilters['search'] === null || newFilters['search'] === '') {
      delete newFilters['search']
    }

    setFilters(newFilters);
  };

  const onDeleteFilter = (key) => {
    const newFilters = { ...filters };
    delete newFilters[key];
    if (key === 'search') {
      delete newFilters['search'];
    }
    if (key === 'marca') {
      delete newFilters['modelo'];
    }
    if (key === 'marca' || key === 'modelo') {
      delete newFilters['version'];
    }
    if (key === 'marca' || key === 'modelo' || key === 'version') {
      delete newFilters['search'];
    }
    if (key === 'precio') {
      delete newFilters['precioMin'];
      delete newFilters['precioMax'];
    }
    if (key === 'kilometersRange') {
      delete newFilters['kmMin'];
      delete newFilters['kmMax'];
    }
    if (key === 'year') {
      delete newFilters['yearMin'];
      delete newFilters['yearMax'];
    }
    setFilters(newFilters);
  };

  const handleClearFilters = () => {
    onClearFilters()
    setFilters({});
  };

  const handleApplyFilters = () => {
    onApplyFilters(filters);
  };


  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition} scroll="paper">
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            className={classes.close}
            onClick={onClose}
            aria-label="close"
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Grid className={classes.grow}>
            <Link to='/' className={classes.logo}>
              <Logo className={classes.logo}/>
            </Link>
          </Grid>
        </Toolbar>
      </AppBar>
      <DialogContent dividers>
        <CatalogFiltersContainer
          filters={filters}
          data-test-id="catalog-filter-container"
          onUpdateFilter={onUpdateFilter}
          onDeleteFilter={onDeleteFilter}
          onClearFilters={handleClearFilters}
          isLoggedIn={isLoggedIn}
          maxPriceValue={maxPriceValue}
        />
      </DialogContent>
      <DialogActions>
        <Box display="flex" width="100%" justifyContent="center">
          <Button
            data-test-id="apply-filter-btn"
            onClick={handleApplyFilters}>Aplicar Filtros</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
