/*Función para redondeo de un número dependiendo de la base a la que se mande,
  @param {number} es el número que se quiere redondear
  @param {number} es la base por la que se quiere redondear 
  
  @return {number} devuelve el número redondeado

  @example
  const value = roundOut(111111, 10000);
  console.log(value)
  El resultado esperado es:
  120000
*/
export const roundOut = (valueToRoundOut = 0, baseToRound = 1) => {
  const roundOutValue = Math.ceil(valueToRoundOut / baseToRound) * baseToRound;
  return roundOutValue;
}
