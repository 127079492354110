import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ViewStreamIcon from '@material-ui/icons/ViewStream';
import FilterTags from '../../filterTags/filterTags.component';
import Button from '@material-ui/core/Button';
import HiddenOn from '../../common/HiddenOn.component';
import TextResults from '../../common/TextResults';
import clsx from 'clsx';
import SelectV2 from '../../common/SelectV2.component';
import FilterListIcon from '@material-ui/icons/FilterList';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const LABEL_ORDEN = 'Ordenar por';

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.grayScale.g200,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
    },
  },
  filterButton: {
    textTransform: 'none',
    padding: 0,
  },
  mobileBox: {
    padding: '0.25rem 0',
  },
  active: {
    color: theme.palette.primary.main,
  },
  orderby: {
    display: 'flex',
    marginLeft: '10px',
    justifyContent: 'flex-start',
    width: '300px',
    alignItems: 'center',
    '& .MuiFormControl-root': {
      marginTop: '4px'
    }
  },
  orderbyMobile: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '50%',
    alignItems: 'center',
    '& .MuiFormControl-root': {
      marginTop: '4px'
    }
  }
}));

const HeaderCatalogo = (
  { total,
    orderOptions,
    filters,
    marcas, modelos,
    onDeleteFilter,
    onShowFilters,
    onShowOrder,
    onHandleDisplay,
    onUpdateFilter,
    sort,
    labelSort,
    handleChangeSort = () => {},
    display = 'grid'
  }) => {

  const classes = useStyles();

  function handleDeleteFilter (key) {
    onDeleteFilter(key);
  }

  function handleDisplay(evt,dsply) {
    evt.preventDefault();
    onHandleDisplay(dsply);
  }

  function handleSelectOrder(event) {
    handleChangeSort(event.target.value);
    updateFilter('orderBy');
  }

  const updateFilter = (key) => ({ target: { value: eventValue } }, value) => {
    onUpdateFilter(key, value || eventValue);
  };

  let orderLabel = labelSort ? labelSort : LABEL_ORDEN;
  const isFromCatalog = true;

  return(
    <React.Fragment>
      <HiddenOn desktop>
        <Box display="flex" flexDirection="column">
          <Box display="flex" justifyContent="space-between" className={classes.mobileBox}>
            <Box className={classes.orderbyMobile}>
              <Button
                color="primary"
                className={classes.filterButton}
                data-test-id="show-filters-button"
                endIcon={<KeyboardArrowDownIcon/>}
                onClick={onShowOrder}
              >
                {orderLabel}
              </Button>
            </Box>
            <Button
              color="primary"
              className={classes.filterButton}
              onClick={onShowFilters}
              data-test-id="show-filters-button"
              endIcon={<FilterListIcon/>}
            >
              Filtrar
            </Button>
          </Box>
          <Box display="flex" justifyContent="flex-end" className={classes.mobileBox}>
            <TextResults total={total} />
          </Box>
          <FilterTags
            data-test-id="header-tags"
            filters={filters}
            brands={marcas}
            modelos={modelos}
            onDelete={handleDeleteFilter}
          />
        </Box>
      </HiddenOn>
      <HiddenOn mobile>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box className={classes.orderby}>
            <SelectV2
              id='select-orderby'
              label={LABEL_ORDEN}
              isFromCatalog={isFromCatalog}
              onChange={handleSelectOrder}
              value={sort}
              options={orderOptions.map((option) => ({
                label: option.label,
                value: option.value
              }))}
            />
          </Box>
          <Box justifyContent="flex-end">
            <IconButton
              disableRipple={true}
              className={clsx(classes.icon, { [classes.active]: display === 'grid' })}
              onClick={(evt) => handleDisplay(evt,'grid')}
              data-test-id="grid-button"
            >
              <ViewModuleIcon />
            </IconButton>
            <IconButton
              disableRipple={true}
              className={clsx(classes.icon, { [classes.active]: display === 'list' })}
              onClick={(evt) => handleDisplay(evt,'list')}
              data-test-id="list-button"
            >
              <ViewStreamIcon/>
            </IconButton>
            <TextResults total={total} />
          </Box>
        </Box>
      </HiddenOn>
    </React.Fragment>
  );
};

HeaderCatalogo.propTypes = {
  total: PropTypes.number,
  filters: PropTypes.object,
  marcas: PropTypes.array,
  modelos: PropTypes.array,
  onDeleteFilter: PropTypes.func,
  onShowFilters: PropTypes.func,
  onShowOrder: PropTypes.func,
  onHandleDisplay: PropTypes.func,
};

export default HeaderCatalogo;
