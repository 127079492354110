import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tag from '../common/tag.component';
import Grid from '@material-ui/core/Grid';
import { formatPricePills, formaterNumber } from '../../utils/currency.utils';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.grayScale.g200,
  },
  labelClass: {
    fontSize: theme.typography.size.small,
  },
  tagsMargin: {
    marginRight: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    backgroundColor: 'inherit',
    border: '1px solid #D1D1D1',
    maxWidth: '100%',
    height: 'auto',
  },
}));

const FilterTags = ({
  filters = {},
  brands,
  modelos: models,
  onDelete = () => {},
}) => {
  const classes = useStyles();
  const conditionLabel = ['Todos', 'O km', 'Usados'];

  const handleDelete = (key) => () => {
    onDelete(key);
  };

  const selectedBrandId = filters.marca?.split(',') || [];
  const brand = brands.find(b =>
    selectedBrandId.every(id =>
      b.id.includes(id)
    )
  );
  const selectedModelId = filters.modelo?.split(',') || [];
  const model = models.find(m =>
    selectedModelId.every(id =>
      m.id.includes(id)
    )
  );

  return (
    <Grid>
        <Tag
          data-test-id="condicionTag"
          value="condicion"
          label={filters.condicion? conditionLabel[filters.condicion] : 'Todos'}
          className={classes.tagsMargin} hideCloseButton
        />
      { filters.marca &&
        <Tag data-test-id="marcaTag" value="marca" label={brand?.name}
          onDelete={handleDelete('marca')} className={classes.tagsMargin} />
      }
      { filters.modelo && <Tag data-test-id="modeloTag" value="modelo" label={model?.name}
        onDelete={handleDelete('modelo')} className={classes.tagsMargin} /> }
      { filters.yearMax && filters.yearMin ? (
          filters.yearMax === filters.yearMin ? (
          <Tag
            data-test-id="yearTag"
            value="año"
            label={filters.yearMax}
            onDelete={handleDelete('year')}
            className={classes.tagsMargin}
          />
        ) : (
          <Tag
            data-test-id="yearRangeTag"
            value="rango de años"
            label={`${filters.yearMin} a ${filters.yearMax}`}
            onDelete={handleDelete('year')}
            className={classes.tagsMargin}
          />
        )
      ) : (
        <>
          { filters.yearMin && (
            <Tag
              data-test-id="yearTag"
              value="año"
              label={`Desde ${filters.yearMin}`}
              onDelete={handleDelete('year')}
              className={classes.tagsMargin}
            />
          )}
          { filters.yearMax && (
            <Tag
              data-test-id="yearTag"
              value="año"
              label={`Hasta ${filters.yearMax}`}
              onDelete={handleDelete('year')}
              className={classes.tagsMargin}
            />
          )}
        </>
      )}
      { filters.precioMin && filters.precioMax ?
        (
          <Tag
            data-test-id="precioTag"
            value="precio"
            label={filters.precioMin === filters.precioMax ? `${formatPricePills(filters.precioMax)}` : `${formatPricePills(filters.precioMin)} a ${formatPricePills(filters.precioMax)}`}
            onDelete={handleDelete('precio')} className={classes.tagsMargin} />
        ) :
        (
          <div>
            { filters.precioMin &&
              <Tag
                data-test-id="precioMinTag"
                value="precioMin"
                label={'Desde ' + formatPricePills(filters.precioMin)}
                onDelete={handleDelete('precioMin')}
                className={classes.tagsMargin}
              />
            }
            { filters.precioMax &&
              <Tag
                data-test-id="precioMaxTag"
                value="precioMax"
                label={'Hasta ' + formatPricePills(filters.precioMax)}
                onDelete={handleDelete('precioMax')}
                className={classes.tagsMargin}
              />
            }
          </div>
        )
      }
      {filters.kmMin && filters.kmMax && (filters.kmMax !== '0') ?
        (filters.kmMin === filters.kmMax ? (
          <Tag
            data-test-id="kmTag"
            value="kilometros"
            label={`${formaterNumber(filters.kmMin)} km`}
            onDelete={handleDelete('kilometersRange')}
            className={classes.tagsMargin}
          />
          )
          :
          filters.kmMin === '100000' && filters.kmMax === '99999999'  ?
            <Tag
              data-test-id="kmMinTag"
              value="kilometros"
              label={`Desde ${formaterNumber(filters.kmMin)} km`}
              onDelete={handleDelete('kilometersRange')}
              className={classes.tagsMargin}
            />
            :
            (
              <Tag
                data-test-id="kmRangeTag"
                value="rango de kilometros"
                label={`${formaterNumber(filters.kmMin)} a ${formaterNumber(filters.kmMax)} km`}
                onDelete={handleDelete('kilometersRange')}
                className={classes.tagsMargin}
              />
            )
        ) :
        (
          filters.kmMax !== '0' ? (
            <>
              { filters.kmMin &&
                <Tag
                  data-test-id="kmMinTag"
                  value="kilometros"
                  label={`Desde ${formaterNumber(filters.kmMin)} km`}
                  onDelete={handleDelete('kmMin')}
                  className={classes.tagsMargin}
                />
              }
              { filters.kmMax &&
                <Tag
                  data-test-id="kmMaxTag"
                  value="kilometros"
                  label={`Hasta ${formaterNumber(filters.kmMax)} km`}
                  onDelete={handleDelete('kmMax')}
                  className={classes.tagsMargin}
                />
              }
            </>
          ) :
            (
              <Tag
                data-test-id="kmMinTag"
                value="kilometros"
                label="0 km"
                onDelete={handleDelete('kilometersRange')}
                className={classes.tagsMargin}
              />
            )
        )
      }
      {filters.version && <Tag data-test-id="versionTag" value="version" label={filters.version}
        onDelete={handleDelete('version')} className={classes.tagsMargin} /> }
      { filters.combustible && <Tag data-test-id="combustibleTag" value="combustible" label={filters.combustible}
        onDelete={handleDelete('combustible')} className={classes.tagsMargin} /> }
      { filters.transmision && <Tag data-test-id="transmisionTag" value="transmision" label={filters.transmision}
        onDelete={handleDelete('transmision')} className={classes.tagsMargin} /> }
      { filters.segmento && <Tag data-test-id="segmentoTag" value="segmento" label={filters.segmento}
        onDelete={handleDelete('segmento')} className={classes.tagsMargin} /> }
    </Grid>

  );
};


export default FilterTags;
