import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { GET_PREDICTIVE_RESULTS } from '../../queries';
import Search from '../../components/common/Search.component';

const AUTOCOMPLETE_INPUT_FIELD_ID = 'autocomplete-input-field'
const SearchCatalogContainer = ({ onSearchCatalog = () => {}, isLoggedIn = false }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [ keyword, setKeyword ] = useState();

  const { data: predictiveData, loading: loadingPredictive } = useQuery(GET_PREDICTIVE_RESULTS, {
    variables: { search: keyword },
    fetchPolicy: 'network-only'
  });

  const handleSearchSubmit = (val) => {
    document.getElementById(AUTOCOMPLETE_INPUT_FIELD_ID)?.blur();
    setKeyword(val);
    onSearchCatalog(val);
  };
  const handleKeywordChange = (val) => {
    setKeyword(val);
  };

  const predictive = predictiveData?.predictive || [];
  let placeholder = isMobile ? ' Marca, modelo, versión …' : 'Marca, modelo, versión, año, combustible o concesionario'
  placeholder = isLoggedIn ? placeholder : 'Ingresá marca, modelo o versión'

  return (
    <Search
      id={AUTOCOMPLETE_INPUT_FIELD_ID}
      onSubmit={handleSearchSubmit}
      onValueChange={handleKeywordChange}
      placeholder={placeholder}
      options={predictive}
      loading={loadingPredictive}
      clearOnSubmit
      noOptionsText={keyword ? `No encontramos la palabra '${keyword}' que estás buscando.` : null}
    />

  );
};

export default SearchCatalogContainer;
