import React from 'react';
import { default as MaterialButton } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
const useStyles = makeStyles(theme => ({
  button: {
    minWidth: '8.5rem',
    border: '1px solid',
    borderRadius: theme.borderRadius.large,
    height: '48px',
    fontSize: theme.typography.size.little,
    fontWeight: theme.typography.weight.semibold,
    lineHeight: 0,
    textTransform: 'initial',
    [theme.breakpoints.down('sm')]: {
      minWidth: '7.5rem',
    },
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.grayScale.g0,
    borderColor: theme.palette.primary.main,
    fontWeight: 500,
    '&:hover':{
      backgroundColor: theme.palette.primary.dark,
      borderColor: theme.palette.primary.dark,
    },
    '&:disabled':{
      backgroundColor: theme.palette.grayScale.g200,
      borderColor: theme.palette.grayScale.g200,
      color: theme.palette.grayScale.g0,
    }
  },
  primaryInverted: {
    backgroundColor: theme.palette.grayScale.g0,
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    '&:hover':{
      backgroundColor: theme.palette.grayScale.g0,
      borderColor: theme.palette.primary.dark,
      color: theme.palette.primary.dark,
    },
    '&:disabled':{
      backgroundColor: theme.palette.grayScale.g200,
      borderColor: theme.palette.grayScale.g200,
      color: theme.palette.grayScale.g0,
    }
  },
  secondary: {
    backgroundColor: theme.palette.grayScale.g0,
    borderRadius: theme.borderRadius.secondary,
    color: theme.palette.grayScale.g500,
    '&:hover':{
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.grayScale.g0,
      borderColor:theme.palette.primary.main,
    },
  },
  outline: {
    fontWeight: 600,
    backgroundColor: 'transparent',
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    '&:hover':{
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
      borderColor: theme.palette.primary.main,
    },
    '&:disabled':{
      borderColor: theme.palette.grayScale.g200,
    }  },
  outlineInverted: {
    fontWeight: 600,
    backgroundColor: 'transparent',
    borderColor: theme.palette.grayScale.g0,
    color: theme.palette.grayScale.g0,
    '&:hover':{
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    '&:disabled':{
      borderColor: theme.palette.grayScale.g200,
    }
  },
  text:{
    fontWeight: 600,
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    color: theme.palette.primary.main2,
    '&:hover':{
      color: theme.palette.primary.dark,
      backgroundColor: 'transparent',
    },
    '&:disabled':{
      borderColor: theme.palette.grayScale.g200,
    }

  }
}));

const Button = ({
  primary,
  primaryInverted,
  secondary,
  outline,
  outlineInverted,
  text,
  disabled,
  onClick,
  className,
  children,
  id,
  ...otherProps }) => {
  const classes = useStyles();

  return (
    <MaterialButton
      disabled={disabled}
      onClick={onClick}
      id={id ?? null}
      className={
        clsx(classes.button, {
          [classes.primary]: primary,
          [classes.primaryInverted]: primaryInverted,
          [classes.secondary]: secondary,
          [classes.outline]: outline,
          [classes.outlineInverted]: outlineInverted,
          [classes.text]: text,
        }, className)
      }
      {...otherProps}
    >
      {children}
    </MaterialButton>
  );
};

export default Button;
