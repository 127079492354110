import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import Autocomplete from './AutocompleteInput.component';
import PropTypes from 'prop-types';
const INPUT_INITIAL_VALUE = '';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  form: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '23rem',
    },
  },
  input: {
    width: '100%',
  },
  container: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: '0.5rem',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '23rem',
    },
  }
}));

const Search = ({ id, onSubmit, onValueChange, options, loading, placeholder, clearOnSubmit, noOptionsText }) => {
  const [value, setValue] = React.useState(INPUT_INITIAL_VALUE);
  const classes = useStyles();

  function handleSubmit(e) {
    e.preventDefault();
    onSubmit(value);
    if (clearOnSubmit) { setValue(INPUT_INITIAL_VALUE); }
  }

  function handleAutocomplete(val) {
    setValue(val);
    onValueChange(val);
  }

  return (
    <Box className={classes.root}>
      <form onSubmit={handleSubmit} className={classes.form}>
        <Box className={classes.container}>
          <Box className={classes.input}>
            <Autocomplete
              id={id}
              data-test-id='autocomplete-keyword'
              label={placeholder}
              options={options}
              loading={loading}
              size='small'
              onChange={handleAutocomplete}
              value={value}
              className={classes.input}
              filterOptions={optionsArray => optionsArray}
              noOptionsText={noOptionsText} />
          </Box>
        </Box>
      </form>
    </Box>
  );
};

Search.propTypes = {
  onSubmit: PropTypes.func,
  options: PropTypes.array,
  loading: PropTypes.bool
};

Search.defaultProps = {
  onSubmit: () => { },
  options: [],
  loading: false
};

export default Search;
