import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../common/Card.component';
import clsx from 'clsx';
import { CarInfo, CarName, CarPill, CarPrice, CarLeyend, CarMedia } from './components';
import { Box } from '@material-ui/core';
import { STOCK_STATES } from '../../utils/constants';

const useStyle = makeStyles(
  (theme) => ({
    mainContainer: {
      display: 'flex',
      flexDirection:'row',
      cursor:'pointer',
      height: '11rem',
      alignItems: 'flex-end'
    },
    description: {
      width: '25%',
      display: 'flex',
      flexDirection:'column',
      justifyContent: 'center',
      marginBottom: '10px'
    },
    carPhoto: {
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      width: '25%',
    },
    carName: {
      width: '100%',
      padding: '1.7rem 0 0 0',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      textAlign: 'center'
    },
    subcontainer: {
      width: '25%',
      padding: '2.5rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent:'center'
    },
    separator:{
      borderLeft:`2px solid ${theme.palette.grayScale.g300}`,
      margin: `0 ${theme.spacing(1)}px`,
      paddingLeft: '1rem',
      height: '7rem',
      display: 'flex',
      alignItems: 'center'
    },
    priceAndLeyend: {
      width: '25%',
      padding:'2rem',
      display: 'flex',
      flexDirection:'column',
      justifyContent:'center',
      alignItems:'flex-end'
    },
    price: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent:'center',
      textAlign: 'center',
      height: '5rem',
      padding: '1rem'
    },
    carLeyend: {
      textAlign: 'center',
      display: 'flex',
      flexDirection:'row',
      justifyContent: 'left'
    },
    wrapLeyend: {
      flexWrap: 'wrap'
    }
  })
);

const CarCardListItem = ({ car, className, onCardClick,disableHover = false, isLoggedIn = false }) => {
  const classes = useStyle();
  let isCarMediaDragging = false;

  const onCarMediaDrag = (isDragging) => {
    isCarMediaDragging = isDragging;
  };

  const handleOnClick = () => !isCarMediaDragging && onCardClick(car);

  const isReserved = car.stock?.every(s => s.estado === STOCK_STATES.RESERVED);

  return (
    <Card
      hoverable={!disableHover}
      className={clsx(classes.card, className)}
      onClick={handleOnClick}>
      <Box className={classes.mainContainer}>
        <Box className={classes.carPhoto}>
          <CarMedia photos={car.images} onDrag={onCarMediaDrag} stock={car.stock} isListItem={true}
            isReserved={isReserved} />
        </Box>
        <Box className={classes.subcontainer}>
          <CarName car={car} horizontal className={classes.carName} />
          <CarInfo car={car} />
        </Box>
        <Box className={classes.description}>
          <Box className={classes.carLeyend}>
            <CarPill car={car} />
          </Box>
          { isLoggedIn &&
            <Box className={classes.carLeyend}>
              <CarLeyend carLot={car.carLot?.name} company={car.company?.name} horizontal isLoggedIn={isLoggedIn}/>
            </Box>
          }
        </Box>
        { car.factoryPrice &&
        <Box className={classes.priceAndLeyend}>
          <Box className={clsx(classes.separator, { [classes.wrapLeyend]: !isLoggedIn })}>
            <CarPrice car={car} className={classes.price} isLoggedIn={isLoggedIn} />
            { !isLoggedIn &&
              <Box>
                <CarLeyend horizontal isLoggedIn={isLoggedIn} />
              </Box>
            }
          </Box>
        </Box>
        }
      </Box>
    </Card>
  );
};

CarCardListItem.propTypes = {
  car: PropTypes.object.isRequired,
  onCardClick: PropTypes.func,
};

export default CarCardListItem;
