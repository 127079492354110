import React from 'react';

import { Router } from '@reach/router';
import CatalogPageContainer from '../containers/listaCatalogo/CatalogPage.container';

const CatalogRouter = (props) => {
  return (
    <Router basepath="/catalogo" >
      <CatalogPageContainer {...props} path="/" />
    </Router>
  );
};

export default CatalogRouter;
