import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import Text from '../common/text.component';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio'


const useStyles = makeStyles((theme) => ({
  sortContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  labelPlace: {
    justifyContent: "space-between",
    width:"-webkit-fill-available",
    marginRight: "0px",
  },
  labelContainer:{
    backgroundColor: '#F6F6F6',
    justifyContent: "space-between",
    marginBottom: "0.50rem",
    borderRadius: "5px"
  },
  title: {
    marginBottom: "1rem"
  }
}));


const CatalogSort = ({
  sort,
  orderOptions,
  handleChangeSortMobile,
  closeDialog,
  changeSortLabel =()=>{}
}) => {
 
  const classes = useStyles();
  const [value, setValue] = React.useState(sort ? JSON.stringify(sort) :'');
  
  const handleChangeOrder = (event) => {
    setValue(event.target.value)
    handleChangeSortMobile(event.target.value)
    closeDialog(true)
  }

  const setSortLabel  =(params)=>{
    changeSortLabel(params)
  }

  return (
    <>
    <Box className={classes.sortContainer}>
      <Text className={classes.title} medium bold>Ordenar por:</Text>
        <RadioGroup 
          data-test-id="sort"
          name="Sort"
          onChange={handleChangeOrder}
          value={value}
        >
          {
            orderOptions.map((option) => {
              return (
                <Box className={classes.labelContainer} key={option.label} >
                  <FormControlLabel
                    name="controlLabel"
                    className={classes.labelPlace}
                    value={JSON.stringify(option.value)} 
                    control={
                      <Radio 
                        sx={{ justifyContent:"space-between" }}
                        color="default" 
                        />
                      }
                    onChange={()=>setSortLabel(option.label)}
                    label={option.label}
                    labelPlacement="start"
                  />
                </Box>
              )
            })
          }
      </RadioGroup>
    </Box>
  </>);
};

export default CatalogSort;
