import React from 'react';
import TextField from '@material-ui/core/TextField';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const LOADING_TEXT = 'Cargando...';
const NO_OPTIONS_TEXT = 'Comience a escribir...';
const useStyles = makeStyles(theme => ({
  searchBox: {
    '& legend > span': {
      display: 'none',
    },
  },
  label: {
    zIndex: 30,
  },
  loading: {
    color: theme.palette.grayScale.g400,
  },
  labelFocused: {
    color: `${theme.palette.secondary.main}`,
  },
  popper: {
    '& .MuiAutocomplete-paper': {
      '@media (max-width: 796px) and (min-width: 480px)': {
        marginRight: 1,
      },
      '@supports ( -moz-appearance:none )': {
        marginRight: 1
      },
      marginTop: '0.5rem',
      borderRadius: theme.borderRadius.large,
      boxSizing: 'border-box',
      boxShadow: 'none',
      border: `1px solid ${theme.palette.grayScale.g200}`,
    }
  },
  menuItemText: {
    display: 'block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: theme.palette.grayScale.g400,
    fontSize: theme.typography.size.smaller
  },
  inputRoot: {
    background: theme.palette.grayScale.g0,
    zIndex: 20,
    '& .MuiAutocomplete-endAdornment': {
      display: 'none',
    },
  },
  searchButton: {
    border: 'none',
    background: 'none',
    margin: '0.3125rem 0 0 0.1875rem',
    color: theme.palette.grayScale.g400
  }
 }));

export default function AutocompleteInput({
  id,
  value,
  loading,
  onChange = () => {},
  label = '',
  className,
  hideSearchIcon,
  handleSearch,
  filterOptions,
  options = [],
  noOptionsText,
  ...autocompleteProps
}) {
  const classes = useStyles();
  const handleAutocompleteChange = (evt, val) => {
    onChange(val);
  };

  return (
    <MuiAutocomplete
      disablePortal={false}
      freeSolo={!noOptionsText}
      id={id}
      loading={loading}
      className={clsx(classes.searchBox, className)}
      classes={{
        popper: classes.popper,
        inputRoot: classes.inputRoot,
        loading: classes.loading,
        option: classes.menuItemText
      }}
      disableClearable
      loadingText={LOADING_TEXT}
      noOptionsText={noOptionsText ? noOptionsText : NO_OPTIONS_TEXT}
      value={value}
      options={options}
      onInputChange={handleAutocompleteChange}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          InputLabelProps={{
            classes: {
              root: classes.label,
              focused: classes.labelFocused,
            },
            ...params.InputLabelProps,
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {!hideSearchIcon && (
                  <button
                    className={clsx(classes.searchButton)}
                  >
                    <SearchIcon onChange={handleSearch}/>
                  </button>
                )}
              </React.Fragment>
            ),
          }}
        />
      )}
      {...autocompleteProps}
    />
  );
}
